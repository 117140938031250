import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/Home",
  },
  {
    path: "/Home",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/Humanities",
    name: "Humanities",
    component: () => import("../views/Humanities.vue"),
  },
  {
    path: "/Overview",
    name: "Overview",
    component: () => import("../views/Overview.vue"),
  },
  {
    path: "/NewsDetail",
    name: "NewsDetail",
    component: () => import("../views/NewsDetail.vue"),
  },
  {
    path: "/HuaiHuaInfo",
    name: "HuaiHuaInfo",
    component: () => import("../views/HuaiHuaInfo.vue"),
  },
  {
    path: "/Optimization", // 怀商优选
    name: "Optimization",
    component: () => import("../views/Optimization.vue"),
  },
  {
    path: "/AdmissionNotice", // 入会须知
    name: "AdmissionNotice",
    component: () => import("../views/AdmissionNotice.vue"),
  },
  {
    path: "/OurNews/:id",
    name: "OurNews",
    component: () => import("../views/huaiHuaMerchantsNews/OurNews.vue"),
  },
  {
    path: "/OurActivity",
    name: "OurActivity",
    component: () => import("../views/huaiHuaMerchantsNews/OurActivity.vue"),
  },
  {
    path: "/OurStyle",
    name: "OurStyle",
    component: () => import("../views/huaiHuaMerchantsNews/OurStyle.vue"),
  },
  {
    path: "/Brief", // 怀商简介
    name: "Brief",
    component: () => import("../views/generalSituation/Brief.vue"),
  },
  {
    path: "/Constitution", // 怀商章程
    name: "Constitution",
    component: () => import("../views/generalSituation/Constitution.vue"),
  },
  {
    path: "/members/:newType", // 会员供需发布
    name: "members",
    component: () => import("../views/members/SupplyDemand.vue"),
  },
  {
    path: "/UnitIntroduce", // 会员资源共享
    name: "UnitIntroduce",
    component: () => import("../views/members/UnitIntroduce.vue"),
  },
  {
    path: "/ResourceSharing", // 会员单位介绍
    name: "ResourceSharing",
    component: () => import("../views/members/ResourceSharing.vue"),
  },

  {
    path: "/Activity11/:id",
    name: "Activity11",
    component: () =>
      import("../views/merchantsNewsDetail/ourActivity/Activity11.vue"),
  },
  // 商会新闻
  {
    path: "/News1/:id",
    name: "News1",
    component: () => import("../views/merchantsNewsDetail/ourNews/News1.vue"),
  },
  // 商会新会员风采
  {
    path: "/Style1",
    name: "Style1",
    component: () => import("../views/merchantsNewsDetail/ourStyle/Style1.vue"),
  },
];

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
});
export default router;
