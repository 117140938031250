<template>
  <div id="app">
    <!-- 路由容器上绑定的key值是为了产品详情页的强制销毁重建 -->
    <router-view v-if="isRouterAlive" :key="$route.path + $route.query.time"/>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>

<style lang="less">
#app{
  position:relative;
}
@import "./styles/index";
</style>

