import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Bootstrap from "bootstrap";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import BackToTop from '@/components/BackToTop';
import VueImg from 'v-img';
import AmapVue from '@amap/amap-vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
AmapVue.config.key = 'dbdcd63b2ecb3ba059903451b7cb8ae8';
 
Vue.component("BackToTop", BackToTop);//ȫ���Զ������
Vue.use(ElementUI);
Vue.use(Bootstrap);
Vue.use(AmapVue);
const vueImgConfig = {
  altAsTitle: true,
}
Vue.use(VueImg, vueImgConfig);

Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");